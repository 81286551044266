import React from 'react'

const Yazi = () => {
    return (
        <div className="com2">
            <h2 className="com2yazisi">
                Size en uygun çözümü biliyor ve çözümünüze ortak olmak istiyoruz, uzmanlık alanımız olan su ve atık su
            konusunda bizden görüş almadan projeye başlamayın.<br />
          •••<br />
            Sürekli kendini geliştirerek yükselen ekibimizle sizlere hizmet vermekten onur duyarız ve aldığınız
            hizmetten memnun kalmanızı kendimize hedef biliriz.<br />
              •••<br />
                İhtiyacınıza uygun çözümler üretmek için sizden haber bekliyoruz.

        </h2>
        </div>
    )
}

export default Yazi
