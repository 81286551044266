import React, { useEffect, useState } from 'react'
import { StaticImage } from "gatsby-plugin-image"


const BackGroundImage = () => {
    const [animasyon, setanimasyon] = useState("animasyongel");
    const [slogan, setslogan] = useState("Tek Bir Damlanın Hesabını Yaparak")

    useEffect(() => {
        const interval = setInterval(() => {
           setanimasyon("animasyongit")
            setTimeout(() => {
                setanimasyon("animasyongel");
                if (slogan==="Tek Bir Damlanın Hesabını Yaparak") {
                    setslogan("Yaşamın Kaynağını Temizliyoruz")
                }else{
                    setslogan("Tek Bir Damlanın Hesabını Yaparak")
                }
            }, 2500);
        }, 11000);
        return () => clearInterval(interval);
      }, [slogan]);

    return (
        <div style={{ display: "grid" }}>
            <StaticImage
                style={{
                    gridArea: "1/1",
                    width: "100%",
                    height: "100vh",

                }}
                className="anasayfa"
                quality={100}
                alt="background"
                src="../../images/arka.jpg"
                formats={["auto", "webp", "avif"]}
                placeholder={"blurred"}
            />
            <div style={{
                    gridArea: "1/1",
                    position: "relative",
                    display: "grid",
                }}>
                <div className="yazilar">
                    <div className="sagyazi">
                        <div className="sagyazi1">
                            <h2>Su</h2>
                            <h3>"Su, Dünya üzerinde bol miktarda bulunan kokusuz, tatsız ve tüm canlıların yaşaması için
                          vazgeçilmez olan kimyasal bileşiktir."</h3>
                        </div>
                        <div className="sagyazi2">
                            <h2>Ve Biz</h2>
                        </div>
                        <div className="sagyazi3">
                            <h2 id="slogan" className={animasyon}>{slogan}</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BackGroundImage
