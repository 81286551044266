import React from 'react'
import { StaticImage } from "gatsby-plugin-image"


const Hizmetler = () => {
    return (
        <>
        <div className="com3">
            <h2 className="com3baslik">Hizmetlerimiz</h2>
            <div className="hizmetlerimiz">
                <div className="hizmet">
                    <h2>Keşif</h2>
                    <StaticImage
                        src="../../images/hizmetler/kesif.jpg"
                        quality={100}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="kesif"
                        className="hizmetimg"
                        placeholder={'blurred'}
                    />
                    <h3>Su arıtma konusunda ihtiyacı olan tesislerde doğru ve uygun çalışma yapılabilmesi için, kapasite
                    tayini, mevcut sistemin işletme bilgileri ve ham su analiz değerleri
                    belirlenmelidir. Bu hizmet mühendislerimiz tarafından ücretsiz olarak verilmektedir.
                </h3>
                </div>
                <div className="hizmet">
                    <h2>Proje</h2>
                    <StaticImage
                        src="../../images/hizmetler/proje.jpg"
                        quality={100}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="proje"
                        className="hizmetimg"
                        placeholder={'blurred'}
                    />
                    <h3>Konusunda uzman, tecrübesini kanıtlamış proje ve AR-GE ekibimizle müşterilerimizin istekleri
                    doğrultusunda istenilen kapasitelerde ve istenilen dizaynlarda en son teknoloji ile en son
                    teknolojik
                    ürünler seçilerek size en uygun projelendirme hizmeti vermekteyiz.
                </h3>
                </div>
                <div className="hizmet">
                    <h2>Montaj</h2>
                    <StaticImage
                        src="../../images/hizmetler/montaj.jpg"
                        placeholder={'blurred'}
                        quality={100}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="montaj"
                        className="hizmetimg"
                    />
                    <h3>Projelendirdiğimiz sistemler konusunda uzman ekibimiz tarafından kurulumu ve montajı yapılarak, sistemi
                    işletmeye hazır hale
                    getirmekteyiz. Tecrübeli ekibimizle montaj sonrası eğitimler ile müşterilerimize
                    sistemleri nasıl kontrol edeceği bilgisini vermekteyiz.
                </h3>
                </div>
                <div className="hizmet">
                    <h2>Danışmanlık</h2>
                    <StaticImage
                        src="../../images/hizmetler/danismanlik.jpg"
                        placeholder={'blurred'}
                        quality={100}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="danismanlik"
                        className="hizmetimg"
                    />
                    <h3>Müşterilerimizin istekleri doğrultusunda önceden yapılmış veya yapılacak olan tesisler için
                    danışmanlık hizmeti vermekteyiz. Teknik şartname oluşturma veya firmanız için gerekli projenin
                    belirlenmesi doğrultusunda uzman mühendislerimizle yanınızdayız.
                </h3>
                </div>
            </div>
        </div>

        {/* HİZMETLER ALT */ }

        <div class="com4">
        <div class="kutucerceve">
            <div class="kutu">
                <i class="fas fa-cogs kutuicon"></i>
                <h3>Teknik Servis</h3>
            </div>
            <div class="kutu">
                <i class="fas fa-tools kutuicon"></i>
                <h3>Periyodik Bakım</h3>
            </div>
            <div class="kutu">
                <i class="fas fa-globe-europe kutuicon"></i>
                <h3>Online Teknik Destek</h3>
            </div>
            <div class="kutu">
                <i class="fas fa-flask kutuicon"></i>
                <h3>Laboratuar</h3>
            </div>
        </div>
    </div>
    </>
    )
}

export default Hizmetler
