import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import BackGroundImage from "../components/HomePage-Components/BackGroundImage"
import Yazi from "../components/HomePage-Components/Yazi"
import Hizmetler from "../components/HomePage-Components/Hizmetler"
import Contact from "../components/Contact"

const IndexPage = () => (
  <Layout>
    <Seo/>
    <BackGroundImage/>
    <Yazi/>
    <Hizmetler/>
    <Contact/>
  </Layout>
)

export default IndexPage
